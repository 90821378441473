import React from "react"
import "./index.css"

import fred from "../../images/fred.jpg"

export const FredFeatureCard = () => {
  return (
    <section className="fred-feature-card">
      <div className="fred-feature-card__text-container">
        <h2>1/8 scale FRED/EOT Devices</h2>
        <ul>
          <li>Custom builds</li>
          <li>Your choice of yellow, gray, or red</li>
          <li>Dummy glad hand additional $5</li>
          <li>Flag holder and flag for $18</li>
        </ul>
        <p className="fred-feature-card__price">$130 USD</p>
      </div>
      <div>
        <img src={fred} alt="FRED" />
      </div>
    </section>
  )
}

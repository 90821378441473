import React from "react"
import "./index.css"

export const About = () => {
  return (
    <div className="about">
      <div>
        <p>
          Creative Train Company manufactures and sells quality 1.5&quot; and
          1.6&quot; scale train cars.
        </p>

        <p>
          Whether you're a hobbyist who's looking for a head-start or a train
          complete with paint and decals, Creative Train Company is the right
          place.
        </p>

        <p>
          Creative Train Company is accepting new orders! Contact us by phone or
          email to discuss your scale trains and see how we can work together to
          fit your needs.
        </p>

        <p>
          Phone: <a href="tel:+12043320492">+1 (204) 332-0492</a>
        </p>
        <p>
          Email:{" "}
          <a href="mailto:henry@creativetrains.ca">henry@creativetrains.ca</a>
        </p>
      </div>
    </div>
  )
}

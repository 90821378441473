import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SRLWrapper } from "simple-react-lightbox"
import { Grid } from "../components/grid"
import { FredFeatureCard } from "../components/fred-feature-card"
import { Testimonials } from "../components/testimonials"
import { About } from "../components/about"
import { ContactForm } from "../components/contact-form"
import "./index.css"

type IndexPageProps = {
  data: {
    galleryContent: {
      nodes: Array<{
        _createdAt: Date
        description: string
        image: {
          asset: {
            url: string
            title: string
          }
        }
      }>
    }
    customerGalleryContent: {
      nodes: Array<{
        _createdAt: Date
        description: string
        image: {
          asset: {
            url: string
            title: string
          }
        }
      }>
    }
  }
}

const IndexPage = ({ data }: IndexPageProps) => (
  <Layout>
    <SEO title="Home" />
    <div className="container" style={{ flex: 1 }}>
      <Grid>
        <FredFeatureCard />
        <About />
        <ContactForm />
      </Grid>

      <section className="index__page-section">
        <div style={{ flex: 2 }}>
          <h2>Gallery</h2>
          <p style={{ marginBottom: 20 }}>
            Here are some of our most recent creations.{" "}
            <Link to="/gallery">View the full gallery</Link>
          </p>
          <p>
            See some of the train cars and accessories that have been built by
            Creative Train Company in the past. Not looking for anything custom?{" "}
            <Link to="/contact">Contact us</Link> for details.
          </p>
          <SRLWrapper>
            <ul className="new gallery">
              {data.galleryContent.nodes
                .sort((a, b) => (a._createdAt >= b._createdAt ? -1 : 1))
                .slice(0, 6)
                .map(({ image, description }) => {
                  return (
                    <li>
                      <a href={image.asset.url} data-attribute="SRL">
                        <figure>
                          <img src={image.asset.url} />
                          <figcaption>{description}</figcaption>
                        </figure>
                      </a>
                    </li>
                  )
                })}
            </ul>
          </SRLWrapper>
        </div>
      </section>
      <Grid>
        <Testimonials />
      </Grid>
      {data.customerGalleryContent.nodes.length > 0 && (
        <section className="index__page-section">
          <div style={{ flex: 2 }}>
            <h2>Customer Gallery</h2>
            <p>
              Don't take our word for it, check out these pictures from our
              customers
            </p>
            <SRLWrapper>
              <ul className="index__customer-gallery">
                {data.customerGalleryContent.nodes
                  .sort((a, b) => (a._createdAt >= b._createdAt ? -1 : 1))
                  .map(({ image, description }) => {
                    return (
                      <li>
                        <a href={image.asset.url} data-attribute="SRL">
                          <figure>
                            <img src={image.asset.url} />
                            <figcaption>{description}</figcaption>
                          </figure>
                        </a>
                      </li>
                    )
                  })}
              </ul>
            </SRLWrapper>
            <p>
              Do you have another one to add to the gallery?{" "}
              <Link to="/contact">Contact us</Link>
            </p>
          </div>
        </section>
      )}
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    galleryContent: allSanityGalleryContent {
      nodes {
        _createdAt
        description
        image {
          asset {
            url
            title
          }
        }
      }
    }
    customerGalleryContent: allSanityCustomerGalleryContent {
      nodes {
        _createdAt
        description
        image {
          asset {
            url
            title
          }
        }
      }
    }
  }
`
